.ProfileNavigation__big_span {
    font-size: 18px;
    font-weight: 400;
}

.ProfileNavigation__medium_span {
    font-size: 16px;
    font-weight: 400;
}

.ProfilePage__parent__div {
    background-color: #F6F7FB;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100%;
}

.ProfilePage__content__div {
    width: 800px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ProfilePage__module_parent_div {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ProfilePage__personal_info_div {
    width: 100%;
}

.ProfilePage__module_content_div {
    width: 100%;
}

.ProfilePage__module_content_div_auto {
    margin-left: 50px;
}

.ProfilePage__btn_size {
    width: 100%;
}

@media only screen and (max-width: 900px) {
    .ProfilePage__content__div {
        
        width: 100%;
    }

    .ProfilePage__btn_size {
        width: 100%;
    }

    .ProfilePage__module__div {
        display: flex;
        flex-direction: column;
    }

    .ProfilePage__module_parent_div {
        display: flex;
        flex-direction: column;
    }
    

    .ProfilePage__personal_info_div {
        width: 100%;
    }
    
    .ProfilePage__module_content_div {
        width: 100%;
    }
    
    .ProfilePage__module_content_div_auto {
        margin-left: 0px;
    }

    .ProfilePage__module_parent_div {
        width: 100%;
    }
    
}