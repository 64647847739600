

.ParkingSidebar__div_parkingList {
    height: calc(100vh - 290px);
  }
  
  .ParkingBottomMenu__div_parkingInfo {
    height: calc(100% - 160px);
  }
  
  .My-Toggle {
  transition: 0.2s;
  }
  
  .BillingForm__btn_shadow {
  box-shadow: 1.5px 1.5px 6px rgb(159, 157, 157);
  }
  
  .ParkingMenu__fontSize_big {
  font-size: 16px;
  }

  .ParkingMenu__bg {
    background: #F9F9F9;
    border-radius: 16px;
  }
  
  .ParkingBottomMenu__div_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: white;
    margin-top: -10px;
    font-size: 16px;
  }  

  .ParkingList__mobile_list {
    display: none;
  }
  .ParkingList__web_list {
    display: flex;
    overflow-y: auto;
  }
  
  @media only screen and (max-width: 1300px) {
    .ParkingListModal__btn_content {
      font-size: 14px;
    }
  }
  
  @media only screen and (max-width: 900px) {
    .ParkingList__mobile_list {
      display: flex;
    }
    .ParkingList__web_list {
      display: none;
    }
  }

  @media only screen and (max-width: 350px) {
    .ParkingListModal__btn_content {
      font-size: 12px;
    }
  }
