.stepper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
}

.step {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #B0B5D0;
    cursor: pointer;
    margin: 0 8px;
    transition: all 0.3s ease-in-out;
}

.step.current {
    background-color: #3266f1;
}

.step.completed {
    background-color: #00ff91;
}

.line_gradient {
    flex: 1;
    height: 2px;
    background-color: #b0b5d0;
    /*background-image: linear-gradient(to right, #00ff91, #127fee);*/
    background-size: 100% 100%;
    transition: background 0.3s ease-in-out;
}

.step.completed ~ .line_gradient {
    background-image: linear-gradient(to right, #00ff91, #127fee);
}
.payment_type_card{
    width: 120px;
    height: 75px;
    border-radius: 16px;
}
.title{
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    /* or 18px */

    display: flex;
    align-items: center;
    letter-spacing: 0.012em;

}

.car_card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 100%;
    height: 56px;
    border: 1px solid #E9E9E9;
    border-radius: 16px;
    background-color: white;
}
.bottom_button_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 2.43056px 24.3056px rgba(0, 0, 0, 0.05);
    border-radius: 32px 32px 0px 0px;
    width: 100%;
    height: 110px;
}

.bottom_button_wrapper button{
    position: absolute;
    bottom: 10px;
    transform: translate(0, 0);
}

/*@media (max-width: 380px) {*/
/*    .bottom_button_wrapper button{*/
/*        left: 6%;*/
/*        width: 320px;*/
/*    }*/
/*}*/
/*@media (max-width: 360px) {*/
/*    .bottom_button_wrapper button{*/
/*        left: 4%;*/
/*        width: 280px;*/
/*    }*/
/*}*/
.billing_modalShow{
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.25);
}
.billing_show{
    padding: 20px;
}
.abonement_card {
    width: 100px !important;
    height: 64px;
    border-radius: 8px;
    border: 1px solid #E9E9E9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;

}
.default_bg{
    background: white;
}
.selected_bg{
    background: #157FFF;
}
.custom_slider .slick-slide{
    margin: 0;
}
.slick-slide{
    margin-right: 10px;
}
.listOfCars{
    position: fixed;
    overflow-y: auto;
    width: 100%;
    top: 10%;
    bottom: 15%;

}
label {
    display: block;
    margin-bottom: 8px;
}

input[type="date"],
input[type="time"] {
    display: inline-block;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 16px;
    font-size: 20px;
    min-width: 90%;
    text-align: center;
    box-sizing: border-box; /* Add this line */

}

input[type="time"] {
    max-width: 110px;
}


input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
    color: #333;
    font-size: 14px;
    padding: 2px;
}

input[type="date"]:focus,
input[type="time"]:focus {
    outline: none;
    border-color: #0077ff;
}
