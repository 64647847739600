.SliderComponent__header {
    font-size: 24px;
    font-weight: 600;
}

.SliderComponent__content {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: #757F8C;
    letter-spacing: 0.2px;
}