.Toggle {
  height: 100%;
  width: 100%;
  z-index: 100;

  display: flex;
  flex-direction: row;


  position: fixed;
  top: 0;
  right: -100%;

  overflow-x: hidden;
  transition: 250ms;
}

.Toggle.active {
  right: 0;
  transition: 250ms;
}

.Aside__navigation_btns {
  font-size: 20px;
  font-weight: 500;
}

.Aside__burger_menu {
  border-radius: 50%;
}

.Menu__font-size {
  font-size: 32px;
}

.Login__div {
  height: fit-content;
  width: 120px;
  background-color: rgb(220, 220, 234);
  border-radius: 10px 0 0 10px;
}

.Signup__div {
  height: fit-content;
  width: 120px;
  background-color: rgb(220, 220, 234);
  border-radius: 0 10px 10px 0;
}
