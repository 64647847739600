.icon-background {
    background-image: url('./assets/mapPin.png');

}

.selected-icon-background {
    background-image: url('./assets/SelectedParkingIcon.png');
}

.user-icon-background {
    background-image: url('./assets/test.png');
}

.cluster-icon {
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: blue;
    color: white;
    font-size: 20px;
    border-radius: 50%;
    cursor: pointer;
}
.d-none{
    display: none !important;
}
.show{
    display: flex !important;
}
.modal-info{
    width: 349px;
    height: 172px;
    border-radius: 16px;
    position: fixed;
    /* top: 60%; */
    bottom: 10%;
    left: 50%;
    right: 50%;
    background-color: white;
    padding: 20px;
    transform: translate(-50%, -12%);
}

.modal-info-content{
    display: flex;
    flex-direction: column;
}
.content-text{
    display: flex;
    justify-content: space-between;
}
.parking-info{
    display: flex;
    flex-direction: column;
    gap: 5px;

}
.parking-info .title_parking{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
    /* identical to box height */

    letter-spacing: 0.012em;

    /* On Surface */

    color: #051C3F;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 200px;

}
.parking-info .info{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    /* identical to box height */

    letter-spacing: 0.012em;

    /* On Surface secondary */

    color: #B0B5D0;

    max-width: 176px;
    /* Inside auto layout */

    flex: none;
    order: 0;
    text-overflow: ellipsis;
    flex-grow: 0;
    overflow: hidden;
    white-space: nowrap;
}
.parking-info .name{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    /* identical to box height */

    letter-spacing: 0.012em;

    /* On Surface secondary */

    color: #B0B5D0;

    /* Inside auto layout */

    flex: none;
    order: 0;
    text-overflow: ellipsis;
    flex-grow: 0;
}
.pay-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;
    border: none;
    color: white;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    letter-spacing: 0.012em;
    /* Primary

    Основной цвет элементов
    */
    background: #157FFF;
    border-radius: 16px;
}
@media (max-height: 667px){
    .modal-info-content{
        gap: 10px;
    }
}
.additional-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.price{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    /* identical to box height, or 18px */

    text-align: right;
    letter-spacing: 0.012em;
    color: #B0B5D0;
}
.more{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.012em;
    white-space: nowrap;

    /* Primary color */

    color: #3879F0;
}
.filter{
    background: #FFFFFF;
    border-radius: 16px;
    width: 60px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    /* identical to box height, or 18px */

    letter-spacing: 0.012em;
    color: #B0B5D0;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
    border: 1px solid #B0B5D0;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.find_title{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    letter-spacing: 0.012em;

    /* Secondary color */

    color: #051C3F;
}
@media (max-width: 375px){
    .modal-info{
        width: 320px
    }
}
@media (min-height: 667px){
    .pay-button{
        margin-top: 15px;
    }
}
@media (max-height: 600px){
    .modal-info{
        bottom: 14%;
    }
}