.ProfilePaymentHistory__parent__div {
    background-color: #F6F7FB;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100%;
}

.ProfilePaymentHistory__content__div {
    width: 800px;
    height: 100%;
    display: flex;
    background-color: #F6F7FB;
    flex-direction: column;
}

@media only screen and (max-width: 900px) {
    .ProfilePaymentHistory__parent__div {
        background-color: #F6F7FB;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        width: 100%;
        height: 100%;
    }
    .ProfilePaymentHistory__content__div {
        width: 100%;
        height: 100%;
        display: flex;
        background-color: #F6F7FB;
        flex-direction: column;
    }
}