.ProfileAbonementList__input__border {
    border: 0.5px solid #D4D4FF;
    border-radius: 16px;

    font-size: 16px;
}

.ProfileAbonementlist__bg {
    background-color: #F9F9F9;
}
.ProfileAbonementlist__radius {
    border-radius: 14px;
}

.ProfileAbonementlist__header {
    color: gray;
    font-size: 16px;
    font-weight: 400;
}
.ProfileAbonementlist__content {
    color: black;
    font-size: 16px;
    font-weight: 600;
}
.ProfileAbonementlist__time {
    color: rgb(18,127,238);
    font-size: 16px;
    font-weight: 600;
}

.ProfileAbonementlist__parent__div {
    background-color: #F6F7FB;
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100%;
}

.ProfileAbonementlist__content__div {
    width: 800px;
    height: 100%;
    display: flex;
    background-color: #F6F7FB;
    flex-direction: column;
}

@media only screen and (max-width: 900px) {
    .ProfileAbonementlist__parent__div {
        background-color: #F6F7FB;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        width: 100%;
        height: 100%;
    }
    .ProfileAbonementlist__content__div {
        width: 100%;
        height: 100%;
        display: flex;
        background-color: #F6F7FB;
        flex-direction: column;
    }
}