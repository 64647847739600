.Privacy__link {
  color: rgb(31, 165, 247); 
}

.Privacy__div_parent {
  width: fit-content;
}

.Privacy__link:hover {
  color: rgb(31, 165, 247);

  border-bottom: dashed 1px rgb(31, 165, 247);
}

.Privacy__text_small {
  font-size: 12px;
            font-weight: 400;
            font-family: SF Pro Display;
            color: #127FEE;
}