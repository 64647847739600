.select-border {
    border: 1px solid;
    border-radius: 12px;
    padding: 5px;
}

.icon-border {
    border: 1px solid;
    border-radius: 0px 12px 12px 0px;
    font-size: 24px;
    color: #127FEE;
}