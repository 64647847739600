.PaymentResult__div_parent {
  display: flex;
  justify-content: center;

  width: 100%;
  height: 90%;
}

.PaymentResult__div_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 40%;
  height: 75%;
}



.PaymentResult__icon {
  font-size: 40px;
}

.PaymentResult__icon_success {
  color: green;
}

.PaymentResult__icon_error {
  color: red;
}

.PaymentResult__span_text {
  font-size: 30px;
}

.PaymentResult__img_kaspi {
  height: 40px;
  width: 40px;
}

.PaymentResult__span_bigText {

  font-size: 16px;
  font-weight: 600;
  font-family: SF Pro Display;
}

.PaymentResult__span_smallText {
  color: #A4A5A9;
  font-size: 12px;
  font-weight: 400;
}

.PaymentResult__span_icon {
  font-size: 32px;
  font-weight: 400;
}

@media only screen and (max-width: 900px) {
  .PaymentResult__div_content {
    width: 60%;
  }
}

@media only screen and (max-width: 600px) {
  .PaymentResult__div_content {
    width: 80%;
  }
}

@media only screen and (max-width: 500px) {
  .PaymentResult__div_content {
    width: 100%;
  }
}
