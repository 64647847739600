.payment_coupon{
    display: flex;
    flex-direction: column;
    justify-content: start;
}
.payment_coupon label{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */


    /* paragraph text */

    color: #8193AE;

}
.payment_coupon span{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 22px;
    /* identical to box height, or 105% */


    /* Secondary color */

    color: #051C3F;
}