.InputStyle {
    border: none;
    width: 240px;
    font-size: 32px;
    letter-spacing: 45px;
    outline: none;
    padding-left: 14px;
    margin-top: 10px;
}

.LoginRegistration__parent__div {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 height: 100%;
 background-color: #F6F7FB;
}

.LoginRegistration__content__div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  height: 400px;
  background-color: white;
  border-radius: 14px;
  margin-top: 50px;
}

 .LoginRegistration__btn {
  width: 90%;
 }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 900px) {
  .LoginRegistration__content__div {
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
  }
  .LoginRegistration__parent__div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     width: 100%;
     height: 100%;
     background-color: white;
   }
   .LoginRegistration__btn {
    width: 90%;
   }
}