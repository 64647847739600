.Offer__link {
  cursor: pointer;
  border-bottom: dashed 1px gray;
  margin-left: 5px;
}

.Offer__link:hover {
  color: rgb(31, 165, 247);

  border-bottom: dashed 1px rgb(31, 165, 247);
}

.Offer__text_small {
  font-size: 12px;
            font-weight: 400;
            font-family: SF Pro Display;
            color: #127FEE;
}
