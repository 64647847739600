.ChooseParking__div_parent {
  display: flex;
  flex-direction: row;
  position: relative;

  width: 100%;
  height: 100%;
}

.ChooseParking__maps_height__width {
  height: 100%;
  width: calc(100vw - 400px);

  position: absolute;
  right: 0;
}

.ChooseParking-location-distace {
  position: absolute;
  bottom: 115px;
  right: 0px;
  
  box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 3;
  right: 10px;
  bottom: 125px;
  color: rgb(102,102,102);
  background-color: white;

  border-radius: 4px;
  width: 29px;
  height: 29px;
}

.ChooseParking-location-distace:hover {
  background-color: rgb(240, 240, 240);
}

.ChooseParking__maps_height__width_billing {
  height: 100%;
  width: calc(100vw - 400px);

  position: absolute;
  right: 0;
}

.ChooseParking__bottom_menu_show {
  display: flex;
  position: absolute;
  width: 400px;
  left: 0;
}

.ChooseParking__bottom_billing {
  display: flex;
  width: 400px;
  
}

.ChooseParking__aside_menu_show {
  display: none;
}

.ChooseParking__city_list {
  right: 0;
}

@media only screen and (orientation: landscape) and (max-width: 900px) {
  .ChooseParking__div_parent {
    flex-direction: row;
  }
  
.ChooseParking__city_list {
  left: 0;
}

  .ChooseParking__maps_height__width {
    height: 100%;
    width: 50%;
    position: absolute;
    right: 0;
  }
  
  .ChooseParking__maps_height__width_billing {
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
  }

  .ChooseParking__parking_info {
    height: 200px;
    width: 100%;
  }
  
  .ChooseParking__bottom_menu_show {
    display: flex;
    height: 100%;
    width: 50%;
    position: absolute;
    overflow-y: auto;
    left: 0;
  }

  .ChooseParking__bottom_billing {
    display: flex;
    height: 100%;
    width: 50%;
    position: absolute;
    left: 0;

    overflow-y: auto;
  }

  .ChooseParking__sidebar_show {
    display: none;
  }

  
.ChooseParking__aside_menu_show {
  display: flex;
}
}

.ChooseParking__fontSize_big {
  font-size: 16px;
  font-weight: 600;
}
.ChooseParking__fontSize_small {
  font-size: 14px;
  font-weight: 500;
  color: #A4A5A9;
}


@media only screen and (orientation: portrait) and (max-width: 910px) {
  .ChooseParking__div_parent {
    flex-direction: column;
    position: static;
  }
  .ChooseParking__city_list {
    left: 0;
  }
  .ChooseParking__maps_height__width {
    width: 100%;
    height: 45vh;

    position: relative;
  }

  .ChooseParking__maps_height__width_billing {
    width: 100%;
    height: 40vh;

    position: relative;
  }
  
  .ChooseParking__parking_info {
    height: 200px;
    width: 100%;
  }
  
  .ChooseParking__bottom_menu_show {
    display: flex;
    flex-direction: column;
    height: fit-content;

    position: relative;
    width: 100%;
  }

  .ChooseParking__bottom_billing {
    display: flex;
    height: 40vh;
    width: 100%;
    position: relative;
  }

  .ChooseParking__sidebar_show {
    display: none;
  }

  
.ChooseParking__aside_menu_show {
  display: flex;
}
}