.MasterLayout__content_div {
    margin-top: 60px;
}

.MasterLayout__mobile_view {
    display: flex;
}

@media only screen and (max-width: 900px) {
    .MasterLayout__mobile_view {
        display: none;
    }
    .MasterLayout__content_div {
        margin-top: 0px;
    }
}

@media screen and (orientation: landscape) and (max-width: 900px) {
    .MasterLayout__mobile_view {
        display: none;
    }
    .MasterLayout__content_div {
        margin-top: 0px;
    }
}