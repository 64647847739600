.PaymentMethodsForm__div_parent {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: fit-content;
  width: 100%;
}

.PaymentMethodsForm__div_paymentType {
  display: flex;
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
  background: #F9F9F9;
  border-radius: 16px;
  padding: 8px;
  border: 1px solid #E9E9E9;
  align-items: center;
}

.PaymentMethodsForm__div_content {
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 40%;
}

@media only screen and (max-width: 800px) {
  .PaymentMethodsForm__div_content {
    width: 60%;
  }
}

@media only screen and (max-width: 500px) {
  .PaymentMethodsForm__div_content {
    width: 80%;
  }
}

@media only screen and (max-width: 350px) {
  .PaymentMethodsForm__div_content {
    width: 100%;
  }
}
