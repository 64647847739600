.CarInput__tooltip_parent_div {
    width: 250px;
    height: fit-content;
    flex-wrap: wrap;
    right: -26px;
    top: 24px;
}

.CarInput__border {
    border: 0.5px solid #D4D4FF;
    border-radius: 16px;
}

.CarInput__tooltip {
    width: 100%;
    background-color: black;
    z-index: 2;
    border-radius: 5px;
}

.CarInput__tooltip::before {
  content: "";
  position: absolute;
  top: -10px;
  right: 28px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  
  border-color: transparent transparent black transparent;
}

.CarInput__parent_div {
    outline: none;
}

.CarInput__parent_div:focus {
    outline: 1px solid #666
}