.parking_card{
    display: flex;
    justify-content: space-between;
    height: 100px;
    background-color: whitesmoke;
    width: 100%;
    border-radius: 16px;
    border: 1px solid #E9E9E9;
    margin-top: 10px;
    padding: 10px;
}
.parking_card img{
    width: 80px;
    height: 80px;
    border-radius: 16px;

}
.main_info{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 130px;
}
.main_info span:first-child{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.012em;

    /* On Surface */

    color: #051C3F;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.main_info info_text{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    /* identical to box height */

    letter-spacing: 0.012em;

    /* On Surface secondary */

    color: #B0B5D0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.main_info .info_text img{
    width: 12px;
    height: 14px;
}
.additional_info{
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 5px;
}
.additional_info span{

    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.012em;

    /* On Surface secondary */

    color: #B0B5D0;
}
.additional_info span:first-child{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.012em;
    color: #051C3F;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #157FFF;
}
.additional_info .more{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.012em;
    margin-top: 5%;
    color: #3879F0;
}