.ParkingBottomMenuAbonementCalendar__border {
    font-size: 16px;
    border-radius:16px;
    border: 1px solid #F1F1F1;
    background: white;
    outline: none;
    height: 40px;
}


.ParkingBottomMenuAbonementCalendar__icon {
    font-size: 24px;
    color: #127FEE;
}

.ParkingBottomMenuAbonementCalendar__span {
    font-size: 14px;
}