body{
    font-family: 'SF Pro Display';

}
.authorization_title{
    font-family: 'SF Pro Display';
    font-style: normal !important;
    font-weight: 600;
    font-size: 24px !important;
    line-height: 29px !important;
    color: #051C3F !important;
}
.authorization_description{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    /* or 18px */

    text-align: center;
    letter-spacing: 0.012em;

    /* On Surface secondary */

    color: #B0B5D0;
}
.add_city_description{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #A4A5A9;
}
.primary-button{
    background-color: #157FFF !important;
    color: white !important;
    border-radius: 16px !important;
    font-size: 16px;
    height: 56px;
    border: none;
}
.secondary-button{
    background-color: #9DCAF8 !important;
    color: white !important;
    height: 56px;
    border-radius: 16px;
    border: none;
    font-size: 16px;
}
.manual-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;
    height: 56px;
    font-size: 16px;
    color: #157FFF;
    background: #e5e6e8;
    border: none;

    border-radius: 16px;
}
.back-header-title{

    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
    /* identical to box height */

    letter-spacing: 0.012em;

    /* 04.BLACK */

    color: #303030;
}
.primary{
    background: #157FFF !important;
}
.secondary{
    background: #9DCAF8 !important;
}
.error{
    background: #EC1C24 !important;
}
.search-container {
    position: relative;
    width: 100%;
}

.search-input {
    width: 100%;
    padding: 10px 40px 10px 35px;
    border: 1px solid #E9E9E9;
    border-radius: 16px;
    font-size: 16px;
    background-position: 10px center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
}
.search-input:hover{
    border: 1px solid #157FFF;
}

.search-input:focus {
    outline: none;
    box-shadow: none;
}
.search-input::placeholder {
    margin-left: 15px;
    text-indent: 15px;
}

.search-input::-webkit-search-cancel-button {
    position: relative;
    right: 5px;
    -webkit-appearance: none;
}
.city{

    position: relative;
    color: #ADB9C9;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.012em;
    padding: 5px 0;

}
.city::after{
    content: ''; /* required for the ::after pseudo-element */
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;

    background-color: #8193AE;
    opacity: 0.2;
}


.add-car-form{
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 16px 14px;
    gap: 10px;
    position: relative;
    height: 56px;
    width: 100%;
    background: #FFFFFF;

    border: 1px solid #E9E9E9;
    border-radius: 18px;
}
.switch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 14px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    width: 20px;
    height: 20px;
    top:-2px;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 50%;
}

/* Modal CSS */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

}

.modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 16px;
}

.modal-content p {
    margin-bottom: 10px;
}

.close-button {
    position: absolute;
    top: 15px;
    right: 10px;
    font-size: 24px;
    font-weight: bold;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.modal.open {
    display: flex;
}
.highlighted_info{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    white-space: nowrap;
    letter-spacing: 0.012em;
    color: #157FFF;
}
.react-tel-input .selected-flag .arrow{
    display: none;
}
.digit_line{
    width: 50px;
    height: 2.5px;
}
.StepperContainer-0-3-1{
    transform: scale(2);
}
.grad {
    width: 300px;
    height: 100px;
    border-style: solid;
    border-width: 10px;
    border-image: linear-gradient(45deg, rgb(0, 143, 104), rgb(250, 224, 66)) 1;

}
.on_surface{
    color: #051C3F !important;
}
.on_surface_secondary{
    color: #B0B5D0 !important;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #157FFF transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.date_and_time_text{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    /* paragrapch text color tertiary */

    color: #ADB9C9;

}
.sf_pro_small{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.012em;

}
.sf_pro_medium{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    letter-spacing: 0.012em;
}
.sf_pro_large{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.012em;

}
.mapboxgl-ctrl-bottom-right{
    bottom: 12%
}
@media (max-height: 667px){
    .mapboxgl-ctrl-bottom-right{
        bottom: 14%
    }
}

.react-tel-input .flag-dropdown.open .selected-flag{
    background: none;
}
.default_color{
    color: #051C3F !important;
}
.selected_color{
    color: white !important;
}
.unselected_color{
    color: #ADB9C9 !important;
}
.highlighted_color{
    color: #157FFF;
}
a.mapboxgl-ctrl-logo{
    display: none;
}

.instruction_city{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: 0.012em;

    /* On Surface */

    color: #051C3F;
}

.responsive_width{
    width: calc(100% - 40px) !important;
}
.fw-400{
    font-weight: 400 !important;
}

.parking_name_title{
    text-align: start;
    font-weight: 600;
    color: #157FFF;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.section_titles{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 19px;
}
@media (max-width: 330px){
    .parking_name_title{
        width: 170px
    }
}
@media only screen and (max-width: 360px) and (min-width: 330px){
    .parking_name_title{
        width: 180px
    }
}
@media only screen and (max-width: 400px) and (min-width: 360px){
    .parking_name_title{
        width: 240px
    }
}
@media only screen and (max-width: 440px) and (min-width: 400px){
    .parking_name_title{
        width: 280px
    }
}
@media only screen and (max-width: 500px) and (min-width: 440px){
    .parking_name_title{
        width: 300px
    }
}