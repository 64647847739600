.rectangle {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e2ebf9;
    border-radius: 100px;
}

.options {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.option {
    font-size: 14px;
    padding: 8px;
    margin: 4px;
    border-radius: 4px;
    cursor: pointer;
    width: 30%;
    background: transparent;
    text-align: center;
    color: #ADB9C9;
    border: none;
}

.selected {
    background: #FFFFFF;
    border-radius: 100px;
    color: #3266F1 !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;


}
