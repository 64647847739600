.Header-parent-div {
    position: absolute;
    top: 0;
    height: 60px;
    width: 100%;
}

.BigHeader-show {
    display: block;
}
.SmallHeader-show {
    display: none;
}

.HeaderContent {
    display: flex;
}

.HeaderBurger {
    display: none;
}

@media only screen and (max-width: 650px) {
    .HeaderContent {
        display: none;
    }
    .HeaderBurger {
        display: flex;
    }
    .Header-show {
        display: none;
    }
    .SmallHeader-show {
        display: block;
    }
}