.add-card-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100px;
    padding: 12px 16px;
    border: 1px dashed #ADB9C9;
    border-radius: 16px;
    color: #B0B5D0;
    font-size: 16px;
    font-weight: bold;
    background-color: transparent;
    cursor: pointer;
}

.add-card-btn:hover {
    background-color: #0079BF;
    color: #FFFFFF;
}
.card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    height: 110px;
    padding: 16px;
    border: 1px solid #E9E9E9;
    border-radius: 16px;
}
.highlighted_card{
    border: 1px solid #157FFF !important;
}
.highlighted_number{
    color: #157FFF !important;
}
.default_number{
    color:  #ADB9C9;
}

.card img {
    width: 100%;
    height: auto;
    margin-bottom: 8px;
}

.card h3 {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: bold;
}

.card p {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 16px;
}

.card a {
    color: #0079BF;
    font-weight: bold;
    text-decoration: none;
}

.card a:hover {
    text-decoration: underline;
}
.delete-card {
    position: absolute;
    bottom: 16px;
    right: 16px;
    cursor: pointer;
}

.delete-card svg {
    width: 24px;
    height: 24px;
}



