.my_cars_list{
    overflow-y: auto;
    position: fixed;
    height: calc(107vh - 240px);
    width: calc(100% - 40px);
}

.cars_list_wrapper{
    flex: 1;
}

.input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    position: relative;
}

.input-container .icon-container {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    z-index: 1;
}

.input-container input {
    padding-left: 3.5rem;
    background-color: white;
    border: 1px solid #E0E0E0;
    border-radius: 18px;
    height: 56px;
    padding-top: 22px;
    font-size: 16px;
}

.input-container label {
    position: absolute;
    top: 16px;
    left: 3rem;
    padding: 0 0.5rem;
    background-color: white;
    color: #999;
    font-size: 12px;
    transform: translateY(-50%);
}

.input-container .error-icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
}

.input-container .error-icon i {
    color: red;
}

.hint-modal {
    position: absolute;
    top: 105%;
    left: 50%;
    height: 60px;
    border-top: 1px solid red;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

    /*transform: translate(-50%, -50%);*/
    z-index: 9999;
    background-color: white;
    padding: 1rem;
}
.hint-modal::after {
    content: '';
    position: absolute;
    top: -20px;
    right: 6px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent red transparent;
}
@supports (-webkit-touch-callout: none) {
    .my_cars_list {
        position: absolute;
        top: 40px;
        left: 0;
        bottom: 0;
        right: 0;
    }
}