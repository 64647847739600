.language-picker {
    position: relative;
}

.language-picker__button {
    background-color: transparent;
    display: flex;
    margin: 10px;
    color: #B0B5D0;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    gap: 5px;
    border: none;
}

.language-picker__button span {
    margin-left: 8px;
}

.language-picker__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;

    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.language-picker__dropdown li {
    list-style: none;
}

.language-picker__dropdown button {
    width: 100%;
    padding: 8px 12px;
    text-align: left;
    background-color: transparent;
    border: 0;
    cursor: pointer;
}

.language-picker__dropdown button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}
