.Menu__navigation_block_disabled {
    background: #F9F9F9;
    border-radius: 8px;
    padding: 10px;
    width: 49%;
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.Menu__navigation_block_active {
    background: #127FEE;
    border-radius: 8px;
    padding: 10px;
    width: 49%;
    height: 100%;   
    display: flex;
    flex-direction: column;
    color: white;
    cursor: pointer;
}

.Menu__navigation_font {
    font-weight: 600;
    font-size: 14px;
}

.Menu__navigation_icon_car {
    width: 74px;
    height: 29px;
}
.Menu__navigation_icon_card {
    width: 50px;
    height: 29px;
}

  
@media only screen and (max-width: 350px) {
    .Menu__navigation_font {
        font-weight: 600;
        font-size: 12px;
    }

    .Menu__navigation_icon_car {
        width: 70px;
        height: 24px;
    }
    .Menu__navigation_icon_card {
        width: 45px;
        height: 24px;
    }
  }
