.slider__controls {
    position: relative;
    z-index: 12;
    width: 230px;
    margin-right: -20px;

}
.slider__pagination {
    text-align: center;
}
.swiper-pagination-bullet {
      width: 13px;
      height: 10px;
      display: inline-block;
      background: #B0B5D0;
      opacity: 0.2;
      margin: 0 5px 12px 0;
      -webkit-border-radius: 20px;
      border-radius: 20px;
      -webkit-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
      -o-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
      transition: opacity 0.5s, background-color 0.5s, width 0.5s;
      -webkit-transition-delay: 0.5s, 0.5s, 0s;
      -o-transition-delay: 0.5s, 0.5s, 0s;
      transition-delay: 0.5s, 0.5s, 0s;
  }


.swiper-pagination-clickable .swiper-pagination-bullet {
      cursor: pointer;
  }

.slider__pagination .swiper-pagination-bullet-active {
      opacity: 1;
      background: #157FFF;
      width: 40px;
      -webkit-transition-delay: 0s;
      -o-transition-delay: 0s;
      transition-delay: 0s;
  }

.slider__button-next,.slider__button-next_default,
  .slider__button-prev {
      position: absolute;
      top: 50%;
      width: 27px;
      height: 25px;
      margin-top: -22px;
      z-index: 10;
      cursor: pointer;
      -webkit-background-size: 27px 44px;
      background-size: 27px 44px;
      background-position: center;
      background-repeat: no-repeat;
  }

.slider__button-prev {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
      left: 40px;
      right: auto;
  }

.skip-btn {
    margin-bottom: 36px;
    margin-left: 16px;
}
.slider__button-next {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
      right: 40px;
      left: auto;
  }
.slider__button-next_default {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
    right: 40px;
    filter: invert(91%) sepia(4%) saturate(47%) hue-rotate(189deg) brightness(80%) contrast(0%);
    left: auto;
}

.slider__button-prev:focus,
.slider__button-next_default:focus,
  .slider__button-next:focus {
      outline: none !important;
  }

