/*-----PAY BILLING FORM-----*/

.BillingForm__div_error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 40%;
  height: 300px;
}

.BillingForm__span_very-big {
  font-size: 32px;
  font-family: SF Pro Display;
  font-weight: 700;
  font-style: normal;
  color: #127FEE;
}

.BillingForm__span_big {
  font-size: 16px;
  font-family: SF Pro Display;
  font-weight: 600;
  font-style: normal;
}

.BillingForm__span_medium {
  font-size: 14px;
  font-family: SF Pro Display;
  font-weight: 400;
  font-style: normal;
  color: #A4A5A9;
}

.BillingForm__span_small {
  font-size: 12px;
  font-family: SF Pro Display;
  font-weight: 400;
  font-style: normal;
  color: #A4A5A9;
}

.BillingForm__checkbox {
  margin-top: 6px;
}

.BillingForm__link {
  cursor: pointer;
  border-bottom: dashed 1px gray;
}

.BillingForm__link:hover {
  color: rgb(31, 165, 247);

  border-bottom: dashed 1px rgb(31, 165, 247);
}

.BillingForm__span_size {
  font-size: 18px;
}

.BillingForm__btn_shadow {
  box-shadow: 1.5px 1.5px 6px rgb(159, 157, 157);
}



/*------LOADER-----*/

.LoaderForm__content_div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 12px;
}

.LoaderForm__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
}
.LoaderForm__loader div {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 6px solid transparent;
  border-top-color: #127FEE;
  border-radius: 50%;
  animation: spinnerOne 1s linear infinite;
}

.LoaderForm__loader div:nth-child(2) {
  border: 6px solid transparent;
  border-bottom-color: #127FEE;
}

.CardLoaderForm__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
}
.CardLoaderForm__loader div {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 3px solid transparent;
  border-top-color: #127FEE;
  border-radius: 50%;
  animation: spinnerOne 1s linear infinite;
}

.CardLoaderForm__loader div:nth-child(2) {
  border: 3px solid transparent;
  border-bottom-color: #127FEE;
}

.BtnLoaderForm__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
}
.BtnLoaderForm__loader div {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 3px solid transparent;
  border-top-color: white;
  border-radius: 50%;
  animation: spinnerOne 1s linear infinite;
}

.BtnLoaderForm__loader div:nth-child(2) {
  border: 3px solid transparent;
  border-bottom-color: white;
}

.BtnLoaderForm__loaderBlue {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
}
.BtnLoaderForm__loaderBlue div {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 3px solid transparent;
  border-top-color: #127FEE;
  border-radius: 50%;
  animation: spinnerOne 1s linear infinite;
}

.BtnLoaderForm__loaderBlue div:nth-child(2) {
  border: 3px solid transparent;
  border-bottom-color: #127FEE;
}

@keyframes spinnerOne {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
