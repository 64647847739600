.ParkingListModal__modal_body {
    height: 70vh;
    overflow-y: auto;
}

.ParkingListModal__icon_fs_small {
    font-size: 24px;
}

.ParkingListModal__icon_fs_big {
    font-size: 36px;
}

.ParkingListModal__bg {
    background: #F9F9F9;
    border-radius: 16px;
  }

.ParkingListModal__fontStyle {
    font-weight: 600;
    font-size: 16px;
}
    



@media only screen and (orientation: portrait) and (max-width: 575px) {
    
    .ParkingListModal__dialog_position {
        position: fixed;
        left: -6px;
        bottom: -20px;
        width: 100%;
    }
}