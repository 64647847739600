.slick-slide img {
    width: 120px;
    height: 75px;
    border-radius: 18px;
}
.slick-slide{
    max-width: 120px;
    margin-right: 10px;
}
.line{
    border: 1px solid #E9E9E9;
    width: 100%;
    display: flex;
    margin-top: 5px;
}
.info-content-wrapper{
    display: flex;
    flex-direction: column;
    padding: 10px ;
}
.info-content{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.left-side{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.left-side img{
    width: auto;
    height: auto;

}
.right-side{

}
.mr{
   margin-right: 5px;
}
.info_text{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.012em;

    /* On Surface secondary */

    color: #B0B5D0;

}
.info_title{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    letter-spacing: 0.012em;

    /* On Surface */

    color: #051C3F;
}
.select_auto{
    background-color: #F2F4F7 !important;
    color: #ADB9C9 !important;
    height: 56px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    border-radius: 16px;
    border: none;
    font-size: 16px;

}
.car_button{
    width: 100%;
    height: 80px;
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.selected_border{
    border: 1px solid #157FFF;
}
.car_button .title{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: 0.012em;

    /* On Background inactive */

    color: #ADB9C9;
}
.car_button .plateNumber{
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    /* or 18px */

    display: flex;
    align-items: center;
    letter-spacing: 0.012em;

    /* On Background inactive */

    color: #ADB9C9;
}
.slick-slide {
    margin: 0 10px;
}
.button_wrapper{
    height: 80%;
    position: fixed;
    overflow-y: auto;
    bottom: 10%;
    width: 100%;
}
/*@media (max-width: 375px){*/
/*    .button_wrapper{*/
/*        max-width: 340px;*/
/*    }*/
/*}*/