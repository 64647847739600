.AbonementInformationModal__modal_body {
    height: 70vh;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    
}

.AbonementInformationModal__icon_fs_small {
    font-size: 24px;
}

.AbonementInformationModal__icon_fs_big {
    font-size: 36px;
}

.AbonementInformationModal__bg {
    background: #F9F9F9;
    border-radius: 16px;
  }

.AbonementInformationModal__fontStyle {
    font-weight: 600;
    font-size: 16px;
}

.AbonementInformationModal__dialog_position-mobile {
    position: fixed;
    bottom: -20px;
    left: -6px;
    width: 100%;
}.AbonementInformationModal__dialog_position-web {
    display: flex;
}

.AbonementInformationModal__text_small {
    font-size: 16px;
    font-weight: 400;
}
.AbonementInformationModal__text_big {
    font-size: 16px;
    font-weight: 600;
}